import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './HomepageComponents.css';
import { getDirectorListSuccess, searchCompanyApiSuccess, setCompanySearchParams, setDirectorSearchParams, setTryPowerSearch } from '../../redux/actions/searchAction';
import {
    ET_SINGLE_SEARCH_COMPANY_COUNT_CLICK, ET_SINGLE_SEARCH_DIRECTOR_COUNT_CLICK,
    ET_SINGLE_SEARCH_COMPANY_CLICK, ET_SINGLE_SEARCH_DIRECTOR_CLICK,
    ET_SINGLE_SEARCH_TRY_OPTION_CLICK, ET_SINGLE_SEARCH_NO_RESULT_CLICK,
    ET_SINGLE_SEARCH_INDUSTRY_COUNT_CLICK, ET_SINGLE_SEARCH_SHAREHOLDER_COUNT_CLICK,
    ET_SINGLE_SEARCH_SHAREHOLDER_CLICK, ET_SINGLE_SEARCH_CHARGES_COUNT_CLICK,
    ET_SINGLE_SEARCH_CHARGES_CLICK, ET_SINGLE_SEARCH_REGION_CLICK,
    ET_SINGLE_SEARCH_REGION_COUNT_CLICK
} from '../../common/trackingConstants'
import { sendActivityTracking } from '../../redux/actions/commonAction';
import { convertToUkCurrencyWithoutPoundSymbol } from '../../common/CurrencyConverter';
import analyticsApi from '../../api/analyticsApi';
import {
    GA_HP_SEARCHBAR_COMPANY, GA_HP_SEARCHBAR_DIRECTOR, GA_PS_BTN_TAG
} from '../../common/config';
import MediaQuery from 'react-responsive';
import { setBusinessPlanOnboardObj } from '../../redux/actions/businessPlanAction';

const SingleSearchDropdown = (props) => {

    const getOptionClickActivityTracking = (eventType, attribute = "") => {
        props.dispatchSendActivityTracking({
            eventPage: props.eventPage,
            eventType,
            attribute1: props.eventCreatedOptionClick,
            attribute2: attribute
        });
    }

    const {
        searchTextboxValue = "",
        companyList = [],
        directorList = [],
        industryList = [],
        shareholderList = [],
        chargesList = [],
        companyCount = 0,
        directorCount = 0,
        industryCount = 0,
        shareholderCount = 0,
        chargesCount = 0,
        headerSingleSearchOptions = {},
        id = "",
        countOptionOnclick = () => { },
        isShow = false,
        optionOnclickFunction = () => { },
        dropDownOptionOnClick = () => { },
        regionList = [],
        regionCount = 0,
        showDropDownTextOnly = false
    } = props;
    let {
        singleSearchTypes = []
    } = props;
    const {
        tryPowerSearch = true
    } = headerSingleSearchOptions;
    singleSearchTypes.length < 1 ?
        tryPowerSearch ?
            singleSearchTypes.push("tryPowerSearch", "company", "director", "industry", "shareholder", "charges", "region") :
            singleSearchTypes.push("company", "director", "industry", "shareholder", "charges", "region") :
        null;
    const firstName = directorList[0] && directorList[0].FirstName ? directorList[0].FirstName : "";
    const middleName = directorList[0] && directorList[0].MiddleName ? directorList[0].MiddleName : "";
    const lastName = directorList[0] && directorList[0].LastName ? directorList[0].LastName : "";
    const diretorFullName = `${firstName} ${middleName} ${lastName}`;
    const isCompanyInclude = singleSearchTypes.includes("company");
    const isDirectorInclude = singleSearchTypes.includes("director");
    const isIndustryInclude = singleSearchTypes.includes("industry");
    const isShareholderInclude = singleSearchTypes.includes("shareholder");
    const isChargesInclude = singleSearchTypes.includes("charges");
    const isRegionInclude = singleSearchTypes.includes("region");
    const isTryPowerSearchInclude = singleSearchTypes.includes("tryPowerSearch");
    const divisionId = industryList[0] && industryList[0].divisionId ? industryList[0].divisionId : "";
    const industryName = industryList[0] && industryList[0].industryName ? industryList[0].industryName : "";
    const shareholderCompanyName = shareholderList[0] && shareholderList[0].companyName ? shareholderList[0].companyName : "";
    const shareholderCompanyId = shareholderList[0] && shareholderList[0].companyId ? shareholderList[0].companyId : "";
    const shareholderName = shareholderList[0] && shareholderList[0].fullName ? shareholderList[0].fullName : "";
    const chargesCompanyName = chargesList[0] && chargesList[0].companyName ? chargesList[0].companyName : "";
    const chargesCompanyId = chargesList[0] && chargesList[0].companyId ? chargesList[0].companyId : "";
    const regionId = regionList[0] && regionList[0].regionId ? regionList[0].regionId : "";
    const regionName = regionList[0] && regionList[0].regionName ? regionList[0].regionName : "";
    return (
        <div id={`${id}_menu`} className={`dropdown-menu ${isShow && searchTextboxValue ? "show" : ""}`} aria-labelledby={id}>
            {!showDropDownTextOnly && companyCount === 0 && directorCount === 0 && industryCount === 0 && shareholderCount === 0 ?
                <DropdownNoResultOption
                    searchTextboxValue={searchTextboxValue}
                    renderLink="/powersearch/company"
                    optionOnClick={() => {
                        optionOnclickFunction();
                    }}
                    getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_NO_RESULT_CLICK)} /> :
                <>
                    {!showDropDownTextOnly && isCompanyInclude ? companyCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/company"}
                            imgSrc={"/assets/images/company_logo.png"}
                            webPimgSrc={"/assets/webpImages/company_logo.webp"}
                            searchCount={companyCount}
                            countText={"Companies"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_COMPANY_COUNT_CLICK, companyCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                            optionOnClick={() => {
                                countOptionOnclick();
                                optionOnclickFunction();
                            }}
                        /> : <DropdownTextOption
                            renderLink={`/company/${companyList[0].companyId}/${encodeURIComponent(companyList[0].companyName.trim().toLowerCase().replace(/ /g, "-"))}`}
                            imgSrc={"/assets/images/company_logo.png"}
                            webPimgSrc={"/assets/webpImages/company_logo.webp"}
                            name={companyList[0].companyName}
                            optionOnClick={() => {
                                optionOnclickFunction();
                            }}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_COMPANY_CLICK, companyList[0].companyId)}
                        /> : null
                    }
                    {showDropDownTextOnly && companyList?.map(item => <DropdownTextWithBtnNoLink
                        imgSrc={"/assets/images/buildings.svg"}
                        webPimgSrc={"/assets/webpImages/buildings.webp"}
                        companyName={item?.companyName}
                        companyId={item?.companyId}
                        getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_COMPANY_COUNT_CLICK, companyCount)}
                        gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                        dropDownOptionOnClick={() => {
                            dropDownOptionOnClick(item);
                        }}
                        key={item?.companyId}
                    />)
                    }
                    {!id.includes("single_search_input_abf_page") && isDirectorInclude ? directorCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/director"}
                            imgSrc={"/assets/images/director_logo.png"}
                            webPimgSrc={"/assets/webpImages/director_logo.webp"}
                            searchCount={directorCount}
                            countText={"Directors"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_DIRECTOR_COUNT_CLICK, directorCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_DIRECTOR}
                            optionOnClick={() => {
                                countOptionOnclick()
                                optionOnclickFunction();
                            }}
                        /> :
                        <DropdownTextOption
                            renderLink={`/director/${directorList[0].PNR}/${encodeURIComponent(diretorFullName.trim().toLowerCase().replace(/ /g, "-").replace(/%/g, ""))}`}
                            imgSrc={"/assets/images/director_logo.png"}
                            webPimgSrc={"/assets/webpImages/director_logo.webp"}
                            name={diretorFullName}
                            optionOnClick={() => optionOnclickFunction()}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_DIRECTOR_CLICK, directorList[0].PNR)}
                        /> : null
                    }
                    {isShareholderInclude ? shareholderCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/shareholder"}
                            imgSrc={"/assets/images/shareholder_toggle_gray.png"}
                            searchCount={shareholderCount}
                            countText={"Shareholders"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_SHAREHOLDER_COUNT_CLICK, shareholderCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                            optionOnClick={() => {
                                countOptionOnclick();
                                optionOnclickFunction();
                            }}
                        /> :
                        <DropdownTextOption
                            renderLink={`/company/${shareholderCompanyId.toString().toUpperCase()}/${encodeURIComponent(shareholderCompanyName.trim().toLowerCase().replace(/ /g, "-"))}`}
                            imgSrc={"/assets/images/shareholder_toggle_gray.png"}
                            name={shareholderName}
                            optionOnClick={() => {
                                optionOnclickFunction();
                            }}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_SHAREHOLDER_CLICK, shareholderCompanyId)}
                        /> : null
                    }
                    {isChargesInclude ? chargesCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/charges"}
                            imgSrc={"/assets/svgs/charges_grey.svg"}
                            searchCount={chargesCount}
                            countText={"Charges & mortgages"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_CHARGES_COUNT_CLICK, chargesCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                            optionOnClick={() => {
                                countOptionOnclick();
                                optionOnclickFunction();
                            }}
                        /> :
                        <DropdownTextOption
                            renderLink={`/company/${chargesCompanyId.toString().toUpperCase()}/${encodeURIComponent(chargesCompanyName.trim().toLowerCase().replace(/ /g, "-"))}`}
                            imgSrc={"/assets/svgs/charges_grey.svg"}
                            name={chargesCompanyName}
                            optionOnClick={() => {
                                optionOnclickFunction();
                            }}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_CHARGES_CLICK, chargesCompanyId)}
                        /> : null
                    }
                    {isIndustryInclude ? industryCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/industry"}
                            imgSrc={"/assets/images/industry_toggle_gray.png"}
                            searchCount={industryCount}
                            countText={"Industries"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_INDUSTRY_COUNT_CLICK, industryCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                            optionOnClick={() => {
                                countOptionOnclick();
                                optionOnclickFunction();
                            }}
                        /> :
                        <DropdownTextOption
                            renderLink={`/industry/${divisionId}/${industryName}`}
                            imgSrc={"/assets/images/industry_toggle_gray.png"}
                            name={industryName}
                            optionOnClick={() => optionOnclickFunction()}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_COMPANY_CLICK, divisionId)}
                        /> : null
                    }
                    {isRegionInclude ? regionCount !== 1 ?
                        <DropdownCountOption
                            renderLink={"/powersearch/region"}
                            imgSrc={"/assets/images/region_gray_logo.png"}
                            searchCount={regionCount}
                            countText={"Regions"}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_REGION_COUNT_CLICK, regionCount)}
                            gtagAnalyticsEvent={GA_HP_SEARCHBAR_COMPANY}
                            optionOnClick={() => {
                                countOptionOnclick();
                                optionOnclickFunction();
                            }}
                        /> :
                        <DropdownTextOption
                            renderLink={`/location/${regionId}/${encodeURIComponent(regionName.trim().toLowerCase().replace(/ /g, "-"))}`}
                            imgSrc={"/assets/images/region_gray_logo.png"}
                            name={regionName}
                            optionOnClick={() => {
                                optionOnclickFunction();
                            }}
                            getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_REGION_CLICK, regionId)}
                        /> : null
                    }
                </>
            }
            {isTryPowerSearchInclude ?
                <DropdownSearchTryOption
                    optionOnClick={() => {
                        optionOnclickFunction();
                    }}
                    setTryPowerSearch={props.dispatchSetTryPowerSearch}
                    getOptionClickActivityTracking={() => getOptionClickActivityTracking(ET_SINGLE_SEARCH_TRY_OPTION_CLICK)}
                /> : null
            }
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSetTryPowerSearch: status => dispatch(setTryPowerSearch(status)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
})
export default withRouter(connect(null, mapDispatchToProps)(SingleSearchDropdown));

export const DropdownCountOption = (props) => {
    const {
        renderLink = "",
        imgSrc = "",
        webPimgSrc = "",
        searchCount = 0,
        countText = "",
        getOptionClickActivityTracking = () => { },
        gtagAnalyticsEvent = "",
        optionOnClick = () => { },
    } = props;

    const handleClickOnLinkDropdownOption = () => {
        analyticsApi.gtag_report_conversion(gtagAnalyticsEvent)
        getOptionClickActivityTracking();
        optionOnClick();
    }
    return (
        <Link to={renderLink} onClick={handleClickOnLinkDropdownOption}>
            <button className="dropdown-item" pomandae2e={countText}>
                <div className="single-search-option-text d-flex align-items-center">
                    <picture>
                        <source type="image/webp" srcSet={webPimgSrc} />
                        <img src={imgSrc} className="single-search-logo" alt="single search logo" />
                    </picture>
                    <span>
                        View <span className="option-color-yellow" pomandae2e={convertToUkCurrencyWithoutPoundSymbol(searchCount)}>
                            {searchCount !== 0 ? convertToUkCurrencyWithoutPoundSymbol(searchCount) : searchCount}</span> {countText}
                    </span>
                </div>
                <i className="fa fa-angle-right right-angle-icon" pomandae2e="arrowbutton" aria-hidden="true" />
            </button>
        </Link>
    );
};

export const DropdownTextOption = (props) => {
    const {
        renderLink = "",
        imgSrc = "",
        webPimgSrc = "",
        name = "",
        getOptionClickActivityTracking = () => { },
        optionOnClick = () => { },
        DropdownItemTag = "", //send this prop as blank string or null if you want to render Link
        dropdownItemClassName = ""
    } = props;
    const handleClick = (e) => {
        getOptionClickActivityTracking();
        optionOnClick(e);
    }
    const button = <button className="dropdown-item" pomandae2e={name}>
        <div className="single-search-option-text d-flex align-items-center">
            <picture>
                <source type="image/webp" srcSet={webPimgSrc} />
                <img src={imgSrc} className="single-search-logo" alt="single search logo" />
            </picture>
            <span className="text-capitalize">{name}</span>
        </div>
        <i className="fa fa-angle-right right-angle-icon" aria-hidden="true" />
    </button>

    return (<>
        {DropdownItemTag ? <DropdownItemTag onClick={handleClick} className={`${dropdownItemClassName}`}>
            {button}
        </DropdownItemTag>:
        <Link to={{pathname: renderLink, 
            state: { prevPath: props.prevPath }}} onClick={handleClick}>
            {button}
        </Link>}
        </>
    );
};

export const DropdownTextWithBtnNoLink = (props) => {
    const {
        imgSrc = "",
        webPimgSrc = "",
        companyName = "",
        dropDownOptionOnClick=""
    } = props;

    const handleClick = () => {
        dropDownOptionOnClick()
    }
    return (
        <button className="dropdown-item" onClick={handleClick}>
            <div className="single-search-option-text d-flex align-items-center font-size-14">
                <MediaQuery maxWidth={991.98}>
                    <picture>
                        <source type="image/webp" srcSet={webPimgSrc} />
                        <img src={imgSrc} className="single-search-logo" alt="single search logo"
                        width="22" height="22" />
                    </picture>
                </MediaQuery>
                <span className="text-capitalize" pomandae2e={companyName}>{companyName}</span>
            </div>
        </button>
    );
};

export const DropdownSearchTryOption = (props) => {
    const handleTryOptionClick = () => {
        props.optionOnClick();
        props.setTryPowerSearch(true);
        props.getOptionClickActivityTracking();
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
    }
    return (
        <Link to={"/powersearch/company"} onClick={handleTryOptionClick}>
            <button className="dropdown-item borderless-option" >
                <div className="single-search-option-text d-flex align-items-center" pomandae2e="powersearchwithadvancedfilter">
                    <picture >
                        <source type="image/webp" srcSet="/assets/webpImages/search_thunder_logo.webp" />
                        <img src={"/assets/images/search_thunder_logo.png"} className="single-search-logo try-search-logo" alt="try power search logo" />
                    </picture>
                    <span className="text-capitalize">
                        Or try our <span className="option-color-yellow">Power Search</span> with advanced filters
                    </span>
                </div>
                <i className="fa fa-angle-right right-angle-icon" aria-hidden="true" />
            </button>
        </Link>
    );
};

export const DropdownNoResultOption = (props) => {
    const {
        searchTextboxValue = "",
        renderLink = "",
    } = props;

    const handleNoResultClick = () => {
        props.getOptionClickActivityTracking();
        props.optionOnClick()
    }

    return (
        <Link to={renderLink}>
            <button className="dropdown-item" onClick={handleNoResultClick}>
                <div className="single-search-option-text d-flex align-items-center" pomandae2e={searchTextboxValue}>
                    <picture >
                        <source type="image/webp" srcSet="/assets/webpImages/no_results_logo.webp" />
                        <img src={"/assets/images/no_results_logo.png"} className="single-search-logo" alt="no result logo" />
                    </picture>
                    <span>
                        <span className="font-weight-normal">No results found for </span>
                        {searchTextboxValue}
                    </span>
                </div>
                <i className="fa fa-angle-right right-angle-icon" aria-hidden="true" />
            </button>
        </Link>
    );
};